import { type ComponentProps, type ReactNode, useId, useState } from "react";

import { Collapsible } from "@/components/Collabsible.tsx";
import { ArrowBottom } from "@/icons/ArrowBottom.tsx";
import { cn } from "@/utils/cn.ts";

type AccordionProps = {
  title: string;
  children: ReactNode;
  openByDefault?: boolean;
} & ComponentProps<"div">;
export const Accordion = ({
  title,
  children,
  openByDefault = false,
  className,
  ...rest
}: AccordionProps) => {
  const [isOpen, setOpen] = useState(openByDefault);
  const id = useId();

  return (
    <div
      className={cn(
        "w-full flex flex-col border-[1px] border-solid border-[#C0C4C5]",
        "xs:border-none",
        className,
      )}
      {...rest}
    >
      <button
        className={cn(
          "relative p-5 pl-[68px] bg-black-blue text-white flex gap-2 justify-between items-end font-mulish font-bold",
          "before:content-[''] after:content-[''] before:absolute after:absolute before:left-[30px] after:left-[30px] before:top-[50%] after:top-[50%] before:w-[18px] after:w-[18px] before:h-[4px] after:h-[4px] before:bg-white after:bg-white after:transition-transform after:duration-300 after:rotate-90",
          "xs:py-[15px] xs:pl-10 xs:pr-4",
          "xs:before:content-none xs:after:content-none",
          {
            "after:rotate-0": isOpen,
          },
        )}
        aria-expanded={isOpen}
        aria-controls={`panel-${id}`}
        onClick={() => setOpen(!isOpen)}
      >
        <div className={cn("text-base text-left leading-[1.444]")}>{title}</div>

        <div
          className={cn(
            "text-[16px] leading-none items-end gap-3.5 hidden",
            "xs:inline-flex",
          )}
        >
          <span className="pb-1">{isOpen ? "Less" : "Read"}</span>

          <ArrowBottom
            className={cn("transition duration-300 scale-100", {
              "-scale-100": isOpen,
            })}
          />
        </div>
      </button>

      <Collapsible isOpen={isOpen}>
        <div
          className={cn(
            "bg-[#F5F5F5] px-[38px] py-[30px] text-dark font-mulish text-[16px] leading-[1.5]",
            "xs:px-[57px] xs:pt-[15px] xs:pb-[23px]",
          )}
          id={`panel-${id}`}
        >
          {children}
        </div>
      </Collapsible>
    </div>
  );
};
